import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box, CircularProgress, Typography } from '@mui/material'
import { Link } from 'ui'
import { Button } from 'ui'
import StandalonePageLayout from '../StandalonePageLayout'

const useStyles = makeStyles()((theme) => ({
  loaderContainer: {
    margin: theme.spacing(2),
  },
  title: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: 500,
    marginTop: 0,
  },
  text: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 400,
  },
}))

export default function BuildUnavailableLayout({ loading, title, text, actionText, actionHref }) {
  const { classes } = useStyles()

  return (
    <StandalonePageLayout>
      {title && (
        <Box>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
        </Box>
      )}
      {loading && (
        <Box paddingBottom={10} className={classes.loaderContainer}>
          <CircularProgress size={50} />
        </Box>
      )}
      <Box maxWidth={600}>
        <Typography variant="h6" className={classes.text}>
          {text}
        </Typography>
      </Box>
      {actionText && (
        <Box marginTop={2}>
          <Link href={actionHref} passHref>
            <Button large variant="contained">
              {actionText}
            </Button>
          </Link>
        </Box>
      )}
    </StandalonePageLayout>
  )
}
