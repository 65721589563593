import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box } from '@mui/material'
import { Link, Logo, SidebarHeader } from 'ui'

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
    flex: '1',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto 0',
  },
}))

export default function StandalonePageLayout({ children }) {
  const { classes } = useStyles()

  return (
    <Box className={classes.root}>
      <SidebarHeader>
        <Link href="/" passHref>
          <Logo />
        </Link>
      </SidebarHeader>
      <Box className={classes.main}>
        <Box className={classes.center}>{children}</Box>
      </Box>
    </Box>
  )
}
